import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

const WelcomePage = () => (
  <div>
    <p>Welcome! Please sign in.</p>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  error: null,
};

class SignInFormBase extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {

    this.props.firebase
      .doSignInWithGoogle()
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();

  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">
          Sign In
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = withFirebase(SignInFormBase);

export default WelcomePage;

export { SignInForm };
