import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../Session';
import FlowRunViz from './FlowRunViz';

import * as ROUTES from '../../constants/routes';


class ViewFlowRun extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      run: null,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = () => {
    const flowId = this.props.match.params.flowId;
    const runId = this.props.match.params.runId;
    return this.props.firebase.run(flowId, runId).onSnapshot(
      snapshot => {
        this.setState({
          run: snapshot.data(),
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe =  this.fetchData();
  }


  render() {
    const { run, loading } = this.state;
    const flowId = this.props.match.params.flowId;
    const runId = this.props.match.params.runId;

    const header = <div>
      <h2>
        <Link to={ROUTES.HOME}
            style={{textDecoration: 'none'}}>
          flows
        </Link>
        /
        <Link to={`/flow/${flowId}`}
            style={{textDecoration: 'none'}}>
          {flowId}
        </Link>
        /
        <span style={{color: '#FF5959'}}>
          {runId}
        </span>
      </h2>
    </div>

    if ( loading ) {
      return(
        <div>
          {header}
          Loading..
        </div>
      )
    }

    if ( !loading && !run ) {
      return(
        <div>
          {header}
          No runs found.
        </div>
      )
    }

    if ( !loading && run ) {
      const triggeredAt = this.state.run.triggered_at_str
      return (
        <div>
          {header}
          <FlowRunViz flowId={flowId} runId={runId} triggeredAt={triggeredAt}/>
        </div>
      );
    }
  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(ViewFlowRun);
