import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { withAuthorization } from '../Session';

class FlowList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      flows: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const group = this.props.authUser.group
    this.unsubscribe = this.props.firebase.flows(group).onSnapshot(
      querySnapshot => {
        var flowList = []
        querySnapshot.forEach(flow => {
          flowList.push({
            id: flow.id,
            schedule: flow.data().schedule
          })
        });

        this.setState({
          flows: flowList,
          loading: false,
        });
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { flows, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading..</div>}
        {!loading && flows.length === 0 && <div>No flows found.</div>}
        <FlowListData flows={flows} />
      </div>
    );
  }
}

const FlowListData = ({ flows }) => (
  <div>
    <h3>Flows</h3>
    These are the scheduled flows and when their schedules. Click to view
    DAGs and DAG runs.
    <ul>
      {flows.map(flow => (
        <li key={flow.id}>
          <span>
            <Link to={`${ROUTES.FLOW}/${flow.id}`}>
              {flow.id}
            </Link>
            <span> </span>
            <code>{flow.schedule}</code>
          </span>
        </li>
      ))}
    </ul>
  </div>
);


const condition = authUser => !!authUser;

export default withAuthorization(condition)(FlowList);
