import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {

  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this.providers = {
      googleProvider: new app.auth.GoogleAuthProvider(),
    };

  };

  // *** Auth API ***

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.providers.googleProvider);

  doSignOut = () =>
    this.auth.signOut();

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // default empty roles
            authUser.group = dbUser ? dbUser.group : null
            next(authUser);
          });
      } else {
        fallback();
      }
    });


  // Users
  user = userId => this.db.doc(`users/${userId}`);

  flows = group => this.db
    .collection('flows')
    .where("owner", "==", group);

  flow = flowId => this.db
    .collection('flows').doc(flowId)

  dags = flowId => this.db
    .collection('flows').doc(flowId)
    .collection('dags')

  dag = (flowId, dagId) => this.db
    .collection('flows').doc(flowId)
    .collection('dags').doc(dagId)

  runs = (flowId, limit) => this.db
    .collection('flows').doc(flowId)
    .collection('runs')
    .orderBy('triggered_at', 'desc')
    .limit(limit);

  run = (flowId, runId) => this.db
    .collection('flows').doc(flowId)
    .collection('runs').doc(runId)

  dagRuns = (flowId, runId, limit) => this.db
    .collectionGroup('dag_runs')
    .where('flow_id', '==', flowId)
    .where('flow_run_id', '==', runId);

  taskInstances = (flowId, runId, dagId) => this.db
    .collectionGroup('task_instances')
    .where('flow_id', '==', flowId)
    .where('flow_run_id', '==', runId)
    .where('dag_id', '==', dagId);

  task = (flowId, dagId, taskId) => this.db
    .collection('flows').doc(flowId)
    .collection('dags').doc(dagId)
    .collection('tasks').doc(taskId)

  tasks = (flowId, dagId) => this.db
    .collection('flows').doc(flowId)
    .collection('dags').doc(dagId)
    .collection('tasks')

}

export default Firebase;
