import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../Session';

class FlowRunList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      runs: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const flowId = this.props.flowId
    this.unsubscribe = this.props.firebase.runs(flowId, 10).onSnapshot(
      querySnapshot => {
        var runList = []
        querySnapshot.forEach(run => {
          runList.push({
            flowId: flowId,
            runId: run.id,
            triggeredBy: run.data().triggered_by
          })
        });

        this.setState({
          runs: runList,
          loading: false,
        });
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { runs, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading..</div>}
        {!loading && runs.length === 0 && <div>No records found.</div>}
        {!loading && runs.length > 0 && <FlowRunListData runs={runs} />}
      </div>
    );
  }
}

const FlowRunListData = ({ runs }) => (
  <div>
    <ul>
      {runs.map(run => (
        <li key={run.runId}>
          <span>
            <Link to={`/flow/${run.flowId}/run/${run.runId}`} >
              {run.runId}
            </Link>
            <span> </span>
            - Triggered by {run.triggeredBy}
          </span>
        </li>
      ))}
    </ul>
    (10 most recent)
  </div>
);


const condition = authUser => !!authUser;

export default withAuthorization(condition)(FlowRunList);
