import React, { Component } from 'react';
import Graph from "react-graph-vis";

import  './DagViz.css';
import { withAuthorization } from '../Session';
import QueryForm from './QueryForm';

class DagViz extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tasks: [],
      queries: {},
      highlight: null
    };
    this.queries = {}
    this.fetchData = this.fetchData.bind(this);
    this.setHighlight = this.setHighlight.bind(this);
  }

  setHighlight(event) {
    var { nodes } = event;
    if ( nodes.length === 1) {
      const node = nodes[0]
      this.setState({
        highlight: node
      });
    } else {
      this.setState({
        highlight: null
      });
    }
  }

  fetchData = () => {
    const flowId = this.props.flowId
    const dagId = this.props.dagId
    return this.props.firebase.tasks(flowId, dagId).onSnapshot(
      querySnapshot => {
        var tasks = []
        var queries = {}
        querySnapshot.forEach(task => {
          tasks.push({
            id: task.id,
            project: task.data().project,
            upstream_task_ids: task.data().upstream_task_ids,
            query: task.data().query
          });
          queries[task.id] = task.data().query
        });
        this.setState({
          tasks: tasks,
          queries: queries,
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe =  this.fetchData();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }


  render() {
    const flowId = this.props.flowId;
    const dagId = this.props.dagId;
    const { tasks, loading, highlight } = this.state;

    if ( loading ) {
      return(
        <div>Loading..</div>
      )
    }

    if ( !loading && tasks.length === 0 ) {
      return(
        <div>No tasks found.</div>
      )
    }

    if ( !loading && tasks.length > 0 ) {

      var graph = {
        nodes: [],
        edges: []
      };

      this.state.tasks.forEach((task, i) => {
        graph.nodes.push({
          id: task.id,
          label: `<b>TASK</b>\n${task.id}`,
          type: 'TASK',
          font: {
            face: 'Ubuntu Mono',
            color: '#66d9ef',
            multi: 'html'
          },
          color: {
            border: '#66d9ef',
            background: '#383E4A',
            opacity: 0,
            highlight: {
              border: '#66d9ef',
              background: '#282D36',
            }
          },
          borderWidth: 2
        })
        if ( task.upstream_task_ids ) {
          task.upstream_task_ids.forEach((fromTaskId, i) => {
            graph.edges.push({
              from: fromTaskId,
              to: task.id
            })
          })
        }
      })

      const options = {
        clickToUse: false,
        layout: {
          hierarchical: {
            sortMethod: 'directed',
            nodeSpacing: 200,
            direction: 'UD'
          },
        },
        nodes: {
          shape: 'box',
          widthConstraint: {
            minimum: 150,
            maximum: 150
          },
          heightConstraint: {
            minimum: 40
          }
        },
        edges: {
          color: "#ffffff"
        }
      };

      const events = {
        select: this.setHighlight
      };

      const highlightForm = ( highlight && !highlight.startsWith('__trigger__')
        ? <div>
            <QueryForm flowId={flowId}
                       dagId={dagId}
                       taskId={highlight}
                       query={this.state.queries[highlight]} />
          </div>
        : null
      )

      return (
        <div>
          <p>Click on a DAG to for details and DAG runs:</p>
          <div className='DagViz'>
            <Graph graph={graph} options={options}
                   events={events} style={{ height: "500px", width:"100%"}} />
          </div>
          <br/>
          {highlightForm}
        </div>

      );

    }


  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(DagViz);
