import React, { Component } from 'react';

import { withAuthorization } from '../Session';

class QueryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.query,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // check whether client has changed
    if (prevProps.query !== this.props.query ) {
      this.setState({value: this.props.query})
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    const flowId = this.props.flowId
    const dagId = this.props.dagId
    const taskId = this.props.taskId
    this.props.firebase.task(flowId, dagId, taskId).set(
      {
        query: this.state.value
      },
      { merge: true },
    );
    event.preventDefault();
  }

  render() {
    return (
      <div>
      <form onSubmit={this.handleSubmit}>
        <label>
          <p>Edit query:</p>
          <textarea value={this.state.value}
                    onChange={this.handleChange}
                    rows="19"
                    cols="79" />
        </label>
        <p><input type="submit" value="Update" /></p>
      </form>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(QueryForm);
