import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from './SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = () => (
  <span>
    <h1><Link to={ROUTES.HOME} style={{color: '#58CBCC', textDecoration: 'none'}}>d5 flow <span style={{fontFamily: 'Source Code Pro'}}><i>[dflow]</i></span></Link> <SignOutButton /></h1>
  </span>
);

const NavigationNonAuth = () => (
  <span>
    <Link to={ROUTES.HOME} style={{color: '#58CBCC', textDecoration: 'none'}}><h1>d5 flow <span style={{fontFamily: 'Source Code Pro'}}><i>[dflow]</i></span></h1></Link>
  </span>
);

export default Navigation;
