import React, { Component } from 'react';
import Graph from "react-graph-vis";
import { Link } from 'react-router-dom';

import  './FlowViz.css';
import { withAuthorization } from '../Session';

class GraphViz extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dags: [],
      highlight: null
    };
    this.fetchData = this.fetchData.bind(this);
    this.setHighlight = this.setHighlight.bind(this);
  }

  setHighlight(event) {
    var { nodes } = event;
    if ( nodes.length === 1) {
      const node = nodes[0]
      this.setState({
        highlight: node
      });
    } else {
      this.setState({
        highlight: null
      });
    }
  }

  fetchData = () => {
    const flowId = this.props.flowId
    return this.props.firebase.dags(flowId).onSnapshot(
      querySnapshot => {
        var dags = []
        querySnapshot.forEach(dag => {
          dags.push({
            id: dag.id,
            project: dag.data().upstream_dag_ids,
            upstream_dag_ids: dag.data().upstream_dag_ids
          })
        });
        this.setState({
          dags: dags,
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe =  this.fetchData();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }


  render() {
    const flowId = this.props.flowId;
    const { dags, loading, highlight } = this.state;

    if ( loading ) {
      return(
        <div>Loading..</div>
      )
    }

    if ( !loading && dags.length === 0 ) {
      return(
        <div>No dags found.</div>
      )
    }

    if ( !loading && dags.length > 0 ) {

      var graph = {
        nodes: [
          {
            id: `__trigger__${this.props.flowId}`,
            label: `<b>TRIGGER</b>\n${this.props.flowSchedule}`,
            type: 'TRIGGER',
            font: {
              face: 'Ubuntu Mono',
              color: '#f92572',
              multi: 'html'
            },
            color: {
              border: '#f92572',
              background: '#383E4A',
              opacity: 0,
              highlight: {
                border: '#f92572',
                background: '#282D36',
              },
            },
            borderWidth: 2
          }
        ],
        edges: []
      };

      this.state.dags.forEach((dag, i) => {
        graph.nodes.push({
          id: dag.id,
          label: `<b>DAG</b>\n${dag.id}`,
          type: 'DAG',
          font: {
            face: 'Ubuntu Mono',
            color: '#66d9ef',
            multi: 'html'
          },
          color: {
            border: '#66d9ef',
            background: '#383E4A',
            opacity: 0,
            highlight: {
              border: '#66d9ef',
              background: '#282D36',
            }
          },
          borderWidth: 2
        })
        if ( dag.upstream_dag_ids ) {
          dag.upstream_dag_ids.forEach((fromDagId, i) => {
            graph.edges.push({
              from: fromDagId,
              to: dag.id
            })
          })
        } else {
          graph.edges.push({
            from: `__trigger__${this.props.flowId}`,
            to: dag.id
          })
        }
      })

      const options = {
        clickToUse: false,
        layout: {
          hierarchical: {
            sortMethod: 'directed',
            nodeSpacing: 200,
            direction: 'UD'
          },
        },
        nodes: {
          shape: 'box',
          widthConstraint: {
            minimum: 150,
            maximum: 150
          },
          heightConstraint: {
            minimum: 40
          }
        },
        edges: {
          color: "#dddddd",
          width: 2
        }
      };

      const events = {
        select: this.setHighlight
      };

      const highlightLink = ( highlight && !highlight.startsWith('__trigger__')
        ? <span>View DAG <Link to={`/flow/${flowId}/dag/${highlight}`} >
            <b>{highlight}</b>
          </Link> details</span>
        : null
      )

      return (
        <div>
          <p>Click on a DAG to for details and DAG runs:</p>
          <div className='FlowRunViz'>
            <Graph graph={graph} options={options}
                   events={events} style={{ height: "500px", maxWidth: "100%"}} />
          </div>
          <br/>
          {highlightLink}
        </div>

      );

    }


  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(GraphViz);
