import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import FlowList from './FlowList';

const DashboardPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <h2>Dashboard</h2>
        <span>You are signed in with <b>{authUser.email}</b>.</span>
        {
          authUser.group
            ? <span> You belong to user group <b>{authUser.group}</b>.</span>
            : <span> You are not a member of any user group. Contact your admin!</span>
        }
        <FlowList />
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(DashboardPage);
