import React from 'react';

import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import ViewFlow from '../ViewFlow';
import ViewDag from '../ViewDag';
import ViewFlowRun from '../ViewFlowRun';
import ViewDagRun from '../ViewDagRun';

import { withAuthentication } from '../Session';

const App = () => (
  <Router>
    <div>
      <Navigation />

      <hr />

      <Route exact path='/' component={LandingPage} />
      <Route exact path='/flow/:flowId' component={ViewFlow} />
      <Route exact path='/flow/:flowId/dag/:dagId' component={ViewDag} />
      <Route exact path='/flow/:flowId/run/:runId' component={ViewFlowRun} />
      <Route exact path='/flow/:flowId/run/:runId/dag/:dagId' component={ViewDagRun} />
    </div>
  </Router>
);

export default withAuthentication(App);
