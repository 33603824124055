import React from 'react';

import { AuthUserContext } from '../Session';
import WelcomePage from './WelcomePage';
import DashboardPage from './DashboardPage';

const LandingPage = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <DashboardPage /> : <WelcomePage />
      }
    </AuthUserContext.Consumer>
  </div>
);

export default LandingPage;
