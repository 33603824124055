import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import { withAuthorization } from '../Session';
import FlowViz from './FlowViz';
import FlowRunList from './FlowRunList';
import FlowTriggerButton from './FlowTriggerButton';

import * as ROUTES from '../../constants/routes';


class ViewFlow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      flow: null,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = () => {
    const flowId = this.props.match.params.flowId;
    return this.props.firebase.flow(flowId).onSnapshot(
      snapshot => {
        this.setState({
          flow: snapshot.data(),
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe =  this.fetchData();
  }


  render() {
    const { flow, loading } = this.state;
    const flowId = this.props.match.params.flowId;

    const header = <div>
      <h2>
        <Link to={ROUTES.HOME}
            style={{textDecoration: 'none'}}>
          flows
        </Link>
        /
        <span style={{color: '#FF5959'}}>
          {flowId}
        </span>
      </h2>
      <FlowTriggerButton flowId={flowId}/>
    </div>

    if ( loading ) {
      return(
        <div>
          {header}
          Loading..
        </div>
      )
    }

    if ( !loading && !flow ) {
      return(
        <div>
          {header}
          No dags found.
        </div>
      )
    }

    if ( !loading && flow ) {
      return (
        <div>
        <div>
          {header}
          <h4>Runs</h4>
          <FlowRunList flowId={flowId} />
        </div>
        <div>
          <h4>Graph</h4>
          <FlowViz flowId={flowId} flowSchedule={this.state.flow.schedule} />
        </div>
        </div>
      );
    }
  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(ViewFlow);
