import React, { Component } from 'react';

import { withAuthorization } from '../Session';
import * as ENDPOINTS from '../../constants/endpoints';

class FlowTriggerButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  onSubmit = event => {

    this.props.authUser.getIdToken().then(token => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'requester': this.props.authUser.email
        })
      };
      fetch(
        `${ENDPOINTS.API}/flow/${this.props.flowId}/trigger`,
        requestOptions
      )
      .then(
        this.setState({ error: null })
      )
      .catch(
        error => this.setState({ error })
      )
    });
    event.preventDefault();
  }

  render() {
    return (
      <div>
      <form onSubmit={this.onSubmit}>
        <label>
          <input type="submit" value="Trigger now" />
        </label>
      </form>
      </div>
    );
  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(FlowTriggerButton);
